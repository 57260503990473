import { useStaticQuery, graphql } from "gatsby"

const ALL_EPHARMACY_PRODUCTS = graphql`
  query MedicineSearchProducts {
    allFlexMedProducts {
      nodes {
        productID
        brand
        molecule
        productTitle
        rxRequired
        rxLevel
        category
        classification
        price_list {
          price_list
          vatex_unit_price
          vat
        }
      }
    }
  }
`

export const useEpharmacyProducts = () => {
  const data = useStaticQuery(ALL_EPHARMACY_PRODUCTS)

  return data
}

import FormInput from "../../Elements/Form/FormInput"
import React from "react"
import Button from "../../Elements/Button"

const MedicineDosageInput = () => {
    return( 
        <div className = 'control'>
            <FormInput
                name = 'indication'
                label = 'Indication'
                helper = 'What condition does this medicine treat?'
                type = 'text'
                hideOptional
            />
            <FormInput
                name = 'dailyIntake'
                label = 'Daily Intake'
                className = 'has-text-centered'
                type = 'number'
                min = {0}
                helper = 'How many are you taking per day?'
                hideOptional
                hasAddons = {true}
                addonRight= {
                    <Button className = "is-static"> per day </Button>
                }
            />
            <FormInput
                name = 'otherSpecifications'
                label = 'Others'
                helper = 'Let us know if you take the meds irregularly?'
                placeholder = "e.g. pill-split, every other day, as needed"
                type = 'text'
                hideOptional
            />
        </div>   

    )
}


export default MedicineDosageInput
import React from "react"
import { Formik, Form } from "formik"

import MedicineInfo from "../Order/MedicineInfo"
import MedicineQuantityInput from "../Order/MedicineQuantityInput"
import MedicineDosageInput from "../Order/MedicineDosageInput"

import TotalPriceInfo from "../Order/TotalPriceInfo"
import { changeQuantity } from "../services/cart"

const AddMedicineSection = ({
  setSelectedMedicine,
  addMedicineToCart,
  selectedMedicine,
}) => (
  <Formik
    initialValues={{ qty: 10 }}
    onSubmit={(values) => {
      setSelectedMedicine(null)
      const others = values.otherSpecifications
        ? values.otherSpecifications
        : "NA"
      if (addMedicineToCart)
        addMedicineToCart(
          selectedMedicine,
          values.qty,
          values.indication,
          values.dailyIntake,
          others
        )
    }}
  >
    {({ values, setFieldValue }) => (
      <Form>
        <div className="mx-2-mobile mt-5">
          <MedicineInfo medicine={selectedMedicine} />
          <MedicineQuantityInput
            changeQuantity={changeQuantity}
            setFieldValue={setFieldValue}
            qty={values.qty}
          />
          <hr className="has-background-light my-0" />
          <MedicineDosageInput />
          <TotalPriceInfo medicine={selectedMedicine} qty={values.qty} />
          <button
            type="submit"
            className="button is-fullwidth is-primary mt-2"
            disabled={
              values.qty < 1 || values.dailyIntake < 1 || !values.indication
            }
          >
            Add to cart
          </button>
        </div>
      </Form>
    )}
  </Formik>
)

export default AddMedicineSection

import React, { useEffect, useState, Fragment, useReducer } from "react"
import classNames from "classnames"

import MobileSearchInput from "./MobileSearch/MobileSearchInput"
import MobileSearchResults from "./MobileSearch/MobileSearchResults"

import { fuzzySearch } from "./services/fuzzySearch"
import styles from "./utils/search.module.scss"
import { MobileSearchReducer } from "./services/reducers/MobileSearchReducer"

export const initialSearchState = {
  searchQuery: "",
  searchQueue: "",
  searchResults: [],
  isLoading: false,
}

const MobileSearch = ({ inputRef, dataSource, onSelect, backRoute }) => {
  const [searchState, searchDispatch] = useReducer(MobileSearchReducer, {
    ...initialSearchState,
  })
  const { searchQuery, searchQueue, searchResults, isLoading } = searchState

  useEffect(() => {
    inputRef.current.focus()
  }, [])

  useEffect(() => {
    searchDispatch({ type: "SET_SEARCH_RESULTS", payload: [] })
    if (searchQuery) {
      searchDispatch({ type: "SET_IS_LOADING", payload: true })
      switch (dataSource.type) {
        case "api":
          break
        case "graphql":
          if (searchQueue) clearTimeout(searchQueue)
          searchDispatch({
            type: "SET_SEARCH_QUEUE",
            payload: setTimeout(async () => {
              await searchDispatch({
                type: "SET_SEARCH_RESULTS",
                payload: fuzzySearch(
                  searchQuery,
                  dataSource.data,
                  dataSource.lookupKeys
                ),
              })
              searchDispatch({ type: "SET_IS_LOADING", payload: false })
            }, 1000),
          })
          break
        default:
          break
      }
    }
  }, [searchQuery])

  const handleValidateMatchDistance = () => {
    const PARTNER_REQUEST_STRING = "partner request"
    return (
      (searchResults.length > 0 && searchResults[0].distance === 1) ||
      PARTNER_REQUEST_STRING.includes(searchQuery.toLowerCase())
    )
  }

  const isExactMatch = handleValidateMatchDistance()

  return (
    <Fragment>
      <MobileSearchInput isLoading={isLoading} backRoute={backRoute}>
        <input
          type="text"
          ref={inputRef}
          placeholder="Search medicines (i.e. Paracetamol)"
          className={classNames("pl-1-mobile", styles["mobile_search__input"])}
          onChange={(event) => {
            searchDispatch({
              type: "SET_SEARCH_QUERY",
              payload: event.target.value,
            })
          }}
          value={searchQuery}
        />
      </MobileSearchInput>
      <MobileSearchResults
        searchResults={searchResults.slice(0, 50)}
        isExactMatch={isExactMatch}
        searchDispatch={searchDispatch}
        onSelect={onSelect}
      />
    </Fragment>
  )
}

export default MobileSearch
